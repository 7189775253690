import consumer from "./consumer"
$(document).on('turbolinks:load', function () {
  var id = $(".destinationid").attr("id");
  if (typeof id !== 'undefined' && id !== false) {
    consumer.subscriptions.create({channel: "DestinationChannel", id: id}, {
      connected() {
        $.ajax({
          url: '/afangastadur/loadscreen/'+id,
          type: 'POST',
          dataType: 'script'
        });
        //$('#drinks').append('<div style="color:white;" class="message"> connected</div>');

        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        dest(data.content);
      }
    });
  }
});