import consumer from "./consumer"

consumer.subscriptions.create("RoomChannel", {
  connected() {
    $('#msg').append('<div class="message"> connected</div>');

    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    $('#msg').append('<div class="message"> ' + data.content + '</div>');
   // $('#msg').append('<div class="message"> testing 123 </div>');
    // Called when there's incoming data on the websocket for this channel
  }
});


let submit_messages;
$(document).on('turbolinks:load', function () {
  submit_messages()
})
submit_messages = function () {
  $('#new_message').on('keydown', function (event) {
    if (event.keyCode == 13) {
      $('input').click()
      event.target.value = ''
      event.preventDefault()
    }
  })
}
