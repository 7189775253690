import consumer from "./consumer"
$(document).on('turbolinks:load', function () {
  var id = $(".kitckenid").attr("id");
  if (typeof id !== 'undefined' && id !== false) {
    consumer.subscriptions.create({channel: "MaturChannel", id: id}, {
      connected() {
        //$('#eldhus1').append('<div style="color:white;" class="message"> connected</div>');

        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        updatelist(data.content);
        currenttime = Date(data.servertime);
      }
    });
  }
});


let submit_messages;
$(document).on('turbolinks:load', function () {
  submit_messages()
})
submit_messages = function () {
  $('#new_message').on('keydown', function (event) {
    if (event.keyCode == 13) {
      $('input').click()
      event.target.value = ''
      event.preventDefault()
    }
  })
}
